.listreservation-table .ehzJbI div:first-child{
    white-space: normal!important;
    text-align: center!important;
}

.listreservation-table .cLiCmx div:first-child{
    white-space: normal!important;
    text-align: left!important;
    min-width: 100px!important;
}

.listreservation-table .HWHoi, .listreservation-table .jqrWof{
    font-weight: 600!important;
}