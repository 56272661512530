/* ==========================================================================
   Menu
========================================================================== */

nav, .navbar{
margin: 0!important;
padding: 0!important;
}

.navbar-brand img {
width:11rem;
}

.navbar-brand .cover{
  object-fit:contain;
}

.mynavbar{
background-color: rgba(239,246,251,1);
}

.header-content{
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
}

.nav-item {
  padding-left: .5rem !important;
}

.user-menu img {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 7px;
  margin-top:-4px;
}

.user-menu .cover  {
object-fit:cover;
}

.dropdown-toggle::after {
    display: none;
}

@media (max-width: 767px) {
.user-menu .user-image {
    float: none;
    margin-right: 0;
    margin-top: -8px;
    line-height: 10px;
  }
}
/*------Menu Partie candidat NON connecté accueil-----*/

.user-menu a  span {
color: #337ab7 ; 
font-size:14px;
font-weight: 600;
}

.card{
    width: 100%;
}

.card-title{
  font-weight: 700;
}



.card-header_content{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
}

.card-header_content .card-title {
    font-size: 1.6rem;
}

.card-header_content .card-subtitle {
    font-size: 1rem;
}

.card-header_content .card-header_title{
    width: 70%;
}
.card-header_content .card-header_share{
    width: 30%;
}

.card-header_content .card-header_share .btn-facebook{
    font-size: 12px!important;
}
.card-header_status {
    font-size: 16px;
    margin-top: 15px;
}

.card-header_status .txt {
    color : #dc3545;
    font-weight: 600;
}

.trips-features {
    display: block;
    width: 100%;
}
.card-header_cta{
    display: contents;
}

.trips-features .features_button, .trips-features .features_button2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    flex: 1;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top : 15px;
}

.trips-features .features_button a{
    width: calc((100% / 5) - (1em - 1em / 5));
    margin-right: 5px;
    margin-bottom: 15px;
}

.trips-features .features_button2 a{
    width: calc((100% / 3) - (1em - 1em / 3));
    margin-right: 5px;
    margin-bottom: 15px;
}

/* ==========================================================================
   Footer
   ========================================================================== */

.section-footer{
	background: #112a3e;
	padding-top: 3.5rem;
	text-align: right;
}


.section-footer p {
	color: white
}


.section-footer  ul {
  list-style:none;
  margin-left:0;
  padding-left:0;
}
.section-footer .list-btn li {
  margin-bottom:15px;
}

.section-footer .logo {
	border:0px solid white;
	height: 70px;
	width:70px;
	 margin-left: auto;
  margin-right: auto;
	overflow:hidden;
}

.section-footer .logo img {
	width:100%;
	opacity:1;
}

.section-footer .copyright {
	opacity:0.4;
}

.section-footer .reso p {
  opacity:0.4;
}

.footer-content{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  color:#fff;
  border-bottom: 1px solid rgba(239,246,251, 0.61);
}

.footer-content a{
  color:white;
}

.footer-content a:hover{
  color: #bfd1e5;
}

.footer-title{
  font-size: 1.3rem;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
}

.touchus{
  margin-top: 30px;
}

.txt-white{
  color:#ffffff!important;
}

/* Contact success css */
.section_contact{
  display: block;
  width: 100%;
}

.section_contact_success{
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
  height: 300px;
}

.back_link{
  margin: 20px 10px;
}

.social-addons .list-social{
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -moz-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    width: 100%;
}
.social-addons .list-social a{
    margin-right: 24px;
}
.social-addons .list-social a i{
    font-size: 25px;
    color: #ffffff;
}

/* ==========================================================================
  Media
   ========================================================================== */
  .media{
    /* background-color: white!important; */
  }
   .media .media-heading{
    font-weight: 700;
}


/* ==========================================================================
  Funders
   ========================================================================== */

 .funders .media{
   margin-top: 30px;
   margin-bottom: 15px;
 }  

.funders .media-left img{
  width: 175px;
  height: 175px;
}

.funders-box {
    margin: 20px 0;
}


/* ==========================================================================
  Careers
   ========================================================================== */

   .careers .media-left img{
    width: 175px;
    height: 175px;
  }
  
  /* ==========================================================================
  Press
   ========================================================================== */

  .press h4{
    font-weight: 700;
  }   
  /* ==========================================================================
  Press
   ========================================================================== */

  .hworks h6{
    font-weight: 700;
  }  
  
  .hworks p>span{
    margin-right: 10px;
  }  
  
  /* ==========================================================================
  Press
   ========================================================================== */

  .trust h4{
    font-weight: 700;
  }
  
  
    /* ==========================================================================
  Opinions
   ========================================================================== */

  .opinions h4{
    font-weight: 700;
  } 

     /* ==========================================================================
  Fas
   ========================================================================== */

  .faq h4, .faq h5{
    font-weight: 700;
    margin-bottom: 15px;
  }

  .faq h6{
    font-weight: 600;
  }

  .trips .travel:hover{
    background: rgba(51, 122, 183, .1);
  }



  .trips .available{
    width: 100%;
    display: block;
    text-align: center;
  }

  .trips span.color-text2{
    margin: 0 5px;
  }

  .trips span.icon{
    margin-right:5px;
  }

  .nav .nav-tabs{
    margin-bottom: 20px;
  }



  /* ###############################################################
    Terms
  #################################################################   */

  .terms .heading {
      font-size : 1.75rem;
      font-weight: 700;
  }

/* ###############################################################
    legales
  #################################################################   */

  .legales .heading {
      font-size : 1.75rem;
      font-weight: 700;
  }


/* ###############################################################
  Privacy
#################################################################   */
  .privacy .heading {
      font-size : 1.75rem;
      font-weight: 700;
  }

  .myprofile .itake, .myprofile .notake{
      text-align: right;
  }

/* ###############################################################
Custome css
#################################################################   */
.f-heading{
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 25px;
}

.f-heading-two{
    font-size: 1.5rem;
    font-weight: bold;

}

.f-heading-three{
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 25px;
}

.f-heading-four{
    font-size: 1rem;
    font-weight: bold;
    margin-top: 15px;
}

.traveldetails .travel-depature{
    font-size: 1.65rem;
}