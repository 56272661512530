@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&display=swap');

*{
    margin: 0;
    padding: 0;
}
.location-search-input{
    font-family: 'Lato', sans-serif;
    width: 200px;
    height: 33px;
    background: none;
    border: 2px solid gray;
    border-radius: 3px;
    font-size: 1rem;
    color: black;

}

.input-suggestion{
    font-family: 'Lato',sans-serif;
    width: 300px;
    height: 33px;
    background-color: white;
    border-bottom: 1px black dotted;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;

}

@media all and (max-width: 1200px){
    .location-search-input {
        width: 100%;
    }
}
