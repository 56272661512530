.formulaire {
  min-height: 2rem;
  padding: 2rem 2rem 0.5rem 2rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px);
  overflow: auto; 
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}


.search-img img{
width:100%
}

.order {
  background-color:rgba(0,0,0,0.03)!important;
  color: #464a4e;
  border: 1px solid rgba(0, 0, 0, 0.125)!important;
  border-radius: calc(0.25rem - 1px);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

/* =====================================================Sections======================================================================================= */

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #007bff;
  border-radius: 3px;
  border: 2px solid #007bff;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
 
    height: 15px;
    width: 15px;
    border-radius: 50%;
  background: #7bb62f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #007bff;
}
input[type=range]::-moz-range-track {
width: 100%;
  height: 4px;
  cursor: pointer;
  background: #007bff;
  border-radius: 3px;
  border: 1px solid #007bff;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
 
    height: 15px;
    width: 15px;
    border-radius: 50%;
  background: #7bb62f;
  cursor: pointer;
    -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 1px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 1px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}


/* =====================================================Sections======================================================================================= */

.travels .status a{
  color: #ffffff;
}

.traveldiff{
  background: rgba(0,123,255,0.04);
}

.travel .travel-inner {
  border-radius:4px;
  padding: 15px 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}

.travel img {
  width: 60px;
  height: 60px;
}

.travel .cover {
  object-fit:cover ;
}

.travel .detail1 .photo {
  position:relative;
  display: inline-block;
 /* border:1px solid red;*/
}

.travel .detail1 .photo i{
  position:absolute;
   bottom: 0;
   right:0;
   background-color:white;
   border-radius: 3px;
   padding:0;
   border:0;   
}

.travel .detail1 .photo i.fa-heart{
   background-color:transparent;  
}


.travel .detail1 .media-body{
  margin:0;
  padding-left: 1rem;
}

.travel .detail1 .media-body .media-heading{
  margin:0;
}

.travel .detail1 .media-body p{
  margin:0;
}

.travel .detail1 .media-left .media-heading {
  display:none;
}

.travel .detail2 {
  border-right: 1px solid  rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.travel .detail3 .value_amount{
  font-size: 24px;
  font-weight: 700;
}

.travel .detail2 .media-left .media-heading {
  font-size : 12px;
}

.travel .detail3 {
	float:right;
}

 .travel .weight  { 
font-size: 24px; 
font-weight: 700;
 }
 
