.section-login{
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.section .section-inner {
  border-radius:calc(0.25rem - 1px);
  background: #fff;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}
.section .heading {
  margin-top: 0;
  margin-bottom: 20px;
  
}

.oops{
	font-size: 5rem;
}

.cover{
	object-fit:cover;
}

.login .form-group, .register .form-group, .myprofile_password .form-group, .Password-change .form-group{
  position: relative;
}

.viewpass{
  position: absolute;
  right: 20px;
  bottom: 15%;
  color: #232323;
  line-height: 1;
  cursor: pointer;
  font-size: 10px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}