/* ======= Formulaire de recherche ======================================================================= */
.formulaire {
  min-height: 20px;
   padding: 20px 20px 5px 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  overflow: auto; 
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

/* ======= Sections======================================================================================= */
.titres p{
   font-size: 80%;
  margin: 0;
  padding-top:1rem;
}


.section {
  margin-bottom: 1rem;
 
}
.section .section-inner {
  border-radius:calc(0.25rem - 1px);
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;

}

.describe .media {
  padding: 20px;
  background: rgba(51, 122, 183, .1);
  border-radius: 4px;
}

.describe .media img {
  width: 40px;
  height: 40px;
}

.describe .media .cover {
  object-fit:cover ;
}

/* ======= Sections======================================================================================= */

.paybox {
  border-radius:calc(0.25rem - 1px);
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}

.paybody { 
  padding: 2rem 1.2rem; 
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); 
}

.payhead { 
  padding: 1.2rem ; 
}

.payfoot { 
  padding: 1.2rem; 
  background: rgba(231,231,231,0.4);
}

.card .media img {
  width: 60px;
  height: 60px;
}

.card .media .cover {
  object-fit:cover ;
}


/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */
/* Ex-Large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .container-fluid {
    width: 1360px;
  }
}


/* Testimonials section */
.testimonials .item {
  margin-bottom: 2rem;
}
.testimonials .item:last-child {
  margin-bottom: 0;
}
.testimonials .item .quote {
  border-left:4px solid #7bb62f;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.testimonials .item .quote .fa:last-child {
  margin-right: 1rem;
}

.testimonials .item .source .date {
  color: #999999;

}


/* ======= Payment Page======================================================================================= */

.entete {
  border-radius:calc(0.25rem - 1px);
  padding: 2rem;
  border: 0px solid rgba(0, 0, 0, 0.125);
  overflow:hidden;
}

.entete h3{
margin: 0;
}

.section .payment-inner {
  border-radius: calc(0.25rem - 1px);
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.03);
}

.section .payment-inner p{
font-size: 12px;
}

.section .payment-inner .command{
padding: 20px;
border-radius: calc(0.25rem - 1px);
border: 1px solid rgba(0, 0, 0, 0.125);
}

.money .media .media-body{
  padding-left: 5px;
}


