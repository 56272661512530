

/*===========================
  --- Header ----
=========================== */

.headerwrap {
  background: url(../img/other/header.png) no-repeat center center;
  background-color: rgba(239,246,251,1);
  max-height: 100%;
  height: 300px;
  position:relative;
  padding-left: 20px;
  padding-top: 100px;
  padding-right: 20%;
    
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.headerwrap .blog-title{
  background-color: rgba(0,0,0,0.3);
  border-radius: calc(0.25rem - 1px);  
  padding: 10px 20px;
  width: 75%;
}

.headerwrap .blog-title .title{
  color: white;
  margin-bottom: 5px;
  font-weight: 700;
}

.headerwrap .blog-title h5{
  color: white;

}

.headerwrap .blog-title hr{
  margin:0 ;
}










